var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{},[_c('Toasts',{ref:"Toasts"}),_c('PlgUsersApi',{ref:"PlgUsersApi"}),_c('div',{staticClass:"container-scroller"},[_c('div',{staticClass:"container-fluid page-body-wrapper full-page-wrapper"},[_c('div',{staticClass:"content-wrapper d-flex align-items-center auth"},[_c('div',{staticClass:"row w-100 flex-grow"},[_c('div',{staticClass:"col-xl-4 col-lg-6 mx-auto"},[_c('div',{staticClass:"auth-form-light text-left p-5"},[_vm._m(0),_c('h4',[_vm._v("Olá!")]),_c('h6',{staticClass:"font-weight-light"},[_vm._v(" Preencha os campos abaixo para redefinir sua senha. ")]),_c('form',{staticClass:"pt-3"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.email),expression:"input.email"}],staticClass:"form-control form-control-lg",class:{
                      'is-invalid': _vm.submitted && _vm.$v.input.email.$error,
                    },attrs:{"type":"email","id":"exampleInputEmail1","placeholder":"Email"},domProps:{"value":(_vm.input.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.input, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.input.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.input.email.required)?_c('span',[_vm._v("Insira seu email.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('b-input-group',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.password),expression:"input.password"}],staticClass:"form-control form-control-lg",class:{
                        'is-invalid': _vm.submitted && _vm.$v.input.password.$error,
                      },attrs:{"type":"password","id":"inputPassword","placeholder":"Senha"},domProps:{"value":(_vm.input.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.input, "password", $event.target.value)}}}),_c('b-button',{class:_vm.eyeIconPass,staticStyle:{"color":"#fff"},attrs:{"aria-hidden":"true","variant":"primary","size":"sm","squared":""},on:{"click":function($event){_vm.passwordFieldCheck ='password', _vm.seePassword()}}}),(_vm.submitted && _vm.$v.input.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.input.password.required)?_c('span',[_vm._v("Insira sua nova senha.")]):_vm._e()]):_vm._e()],1)],1),_c('div',{staticClass:"form-group"},[_c('b-input-group',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.rePassword),expression:"input.rePassword"}],staticClass:"form-control form-control-lg",class:{
                        'is-invalid': _vm.submitted && _vm.$v.input.rePassword.$error,
                      },attrs:{"type":"password","id":"inputRePassword","placeholder":"Confirme sua senha"},domProps:{"value":(_vm.input.rePassword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.input, "rePassword", $event.target.value)}}}),_c('b-button',{class:_vm.eyeIconRePass,staticStyle:{"color":"#fff"},attrs:{"aria-hidden":"true","variant":"primary","size":"sm","squared":""},on:{"click":function($event){_vm.passwordFieldCheck ='rePassword', _vm.seePassword()}}}),(_vm.submitted && _vm.$v.input.rePassword.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.input.rePassword.required)?_c('span',[_vm._v("Confirme sua nova senha.")]):_vm._e()]):_vm._e(),(_vm.submitted && _vm.$v.input.rePassword.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.input.rePassword.sameAsPassword && _vm.$v.input.rePassword.required)?_c('span',[_vm._v("Senhas não conferem!")]):_vm._e()]):_vm._e()],1)],1),_c('div',{staticClass:"mt-3"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn",on:{"click":function($event){$event.preventDefault();return _vm.sendResetEmail.apply(null, arguments)}}},[_vm._v(" Enviar ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn disabled",on:{"click":function($event){$event.preventDefault();return _vm.sendResetEmail.apply(null, arguments)}}},[_c('div',{staticClass:"text-center align-items-center"},[_c('b-spinner',{attrs:{"small":""}})],1)])])])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand-logo"},[_c('img',{attrs:{"src":require("../../assets/images/logo.png")}})])
}]

export { render, staticRenderFns }